import "./App.css";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./Components/Dashboard/Dashboard";
import Login from "./Components/Login/Login";
import Activate from "./Components/Activation/Activate";
import Activate_History from "./Components/Activate History/Activate_History";
import My_Team from "./Components/My Team/My_Team";
import My_Referral from "./Components/My Referral/My_Referral";
import Level_Details from "./Components/Level Details/Level_Details";
import Direct_Leg_Business from "./Components/Direct Leg B/Direct_Leg_Business";
import ReferralIncome from "./Components/Income Report/ReferralIncome";
import RoiIncome from "./Components/Income Report/RoiIncome";
import SalaryIncome from "./Components/Income Report/SalaryIncome";
import ReleaseRoiIncome from "./Components/Income Report/ReleaseRoiIncome";
import RoyaltyIncome from "./Components/Income Report/RoyaltyIncome";
import RewardIncome from "./Components/Income Report/RewardIncome";
import Withdrawal from "./Components/Withdrawal/Withdrawal";
import Withdrawal_History from "./Components/Withdrawal/Withdrawal_History";
import InvestmentClaim from "./Components/Income Report/InvestmentClaim";
import Lifetimereward from "./Components/Income Report/Lifetimereward";
import { Toaster } from "react-hot-toast";
import Registration from "./Components/Registration/Registration";
import CycleDetail from "./Components/Cycle Detail/CycleDetail";
import Home from "./Components/Home/Home";
import HelpDesk from "./Components/HelpDesk/HelpDesk";
import Notification from "./Components/HelpDesk/Notification";

import CtoIncome from "./Components/Income Report/CtoIncome";
import Withdrawal_historyUSD from "./Components/Withdrawal/Withdrawal_historyUSD";
import RoilevelIncome from "./Components/Income Report/RoilevelIncome";
function App() {
  return (
    <>
      <div className="App">
        <Toaster />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Registration />} />
          <Route exact path="/Dashboard" element={<Dashboard />} />
          <Route exact path="/Activate" element={<Activate />} />
          <Route
            exact
            path="/Activation_History"
            element={<Activate_History />}
          />
          <Route exact path="/My_Team" element={<My_Team />} />
          <Route exact path="/My_Referral" element={<My_Referral />} />
          <Route exact path="/Level_Details" element={<Level_Details />} />
          <Route
            exact
            path="/Direct_Leg_Business"
            element={<Direct_Leg_Business />}
          />
          <Route exact path="/levelincome" element={<ReferralIncome />} />
          <Route exact path="/roiIncome" element={<RoiIncome />} />
          <Route exact path="/SalaryIncome" element={<SalaryIncome />} />
          <Route exact path="/ctoIncome" element={<RoyaltyIncome />} />
          <Route exact path="/royaltyIncome" element={<CtoIncome />} />
          <Route exact path="/RewardIncome" element={<RewardIncome />} />
          <Route exact path="/CycleDetails" element={<CycleDetail />} />
          <Route exact path="/Working_Withdrawal" element={<Withdrawal />} />
          <Route exact path="/RoilevelIncome" element={<RoilevelIncome />} />
          <Route
            exact
            path="/ReleaseRoiIncome"
            element={<ReleaseRoiIncome />}
          />
          <Route exact path="/Lifetimereward" element={<Lifetimereward />} />
          <Route
            exact
            path="/Withdrawal_History"
            element={<Withdrawal_History />}
          />
          <Route
            exact
            path="/Withdrawal_historyUSD"
            element={<Withdrawal_historyUSD />}
          />
          <Route exact path="/HelpDesk" element={<HelpDesk />} />
          <Route exact path="/Notification" element={<Notification />} />
          <Route exact path="/InvestmentClaim" element={<InvestmentClaim />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
