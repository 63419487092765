import React, { useEffect, useId, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { API } from "../../Utility/API";
import { updateAuth, updateStatus } from "../../Redux/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { loadWeb3 } from "../../Utility/contract";
import axios from "axios";
import toast from "react-hot-toast";
import DOMPurify from "dompurify";

export default function Registration() {
  let navigate = useNavigate();

  const Id = useId();
  console.log("123", Id);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sponser, setSponser] = useState(searchParams.get("userid"));
  const [selectPosition, setselectPosition] = useState(
    searchParams.get("position") === "Left"
      ? 1
      : searchParams.get("position") === "Right"
      ? 2
      : ""
  );

  const [sponsorName, setsponsorName] = useState(
    "Sponsor Id doesnot exists !!"
  );
  const [registor_Model, setregistor_Model] = useState(false);
  const [connected, setconnected] = useState(true);
  const [sid, setSid] = useState(sponser);
  const [walletAddress, setWalletAddress] = useState("");
  const [LoginId, setLoginId] = useState();

  // console.log("sid", sid);
  const dispatch = useDispatch();

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();
      let response = await axios.get("https://api64.ipify.org/?format=json");
      console.log("response Geolocation", response.data.ip);
      // setIP(response.data.IPv4);
      let ipaddress = response.data.ip;

      if (acc === "No Wallet" || acc === undefined) {
        toast.error("No Wallet");
        setconnected("Wallet is locked");
      } else if (acc === "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSc Network");
        console.log("Wrong Network Please Connect BSC Scan Network");
        setconnected("Wrong Network");
      } else {
        // setaddress(acc)
        setconnected("Wallet is Connected");
        // setLoginId("0X484Demo848sdcssd48");
        setLoginId(acc);
        let res = await API.post(`/login`, {
          password: acc,
          ipaddress: ipaddress,
        });
        let UserID = res.data.data;
        if (res.data.data.result === "Successfull") {
          toast.success(`Login Successful`);
          dispatch(
            updateAuth({
              isAuth: true,
              userId: res.data.data.uid_output,
              jwtToken: res.data.token,
              ipAddress: ipaddress,
            })
          );
          navigate("/Dashboard");
        } else {
          toast.error(res.data.data.result);
        }
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const registerUser = async () => {
    let acc = await loadWeb3();
    let response = await axios.get("https://api64.ipify.org/?format=json");
    // console.log("response Geolocation", response.data.IPv4);
    // setIP(response.data.IPv4);
    let ipaddress = response.data.ip;
    if (!acc) {
      setconnected("Wallet is locked");
    } else if (acc === "Wrong Network") {
      toast.error(" Wrong Network Please Connect BSc Network");
      setconnected("Wrong Network");
    } else if (acc === "No Wallet") {
      toast.error(" Wrong Network Please Connect BSc Network");
      setconnected("Wrong Network");
    } else {
      try {
        if (sid === "" || sid === undefined) {
          toast.error("Enter Upline Id");
        } else {
          let res = await API.post("registration", {
            sponser_id: sid,
            accountnumber: acc,
            psw: acc,
            uid: "0",
            // position: selectPosition,
          });
          console.log("registration", res.data);
          if (res.data.data.result === "Successfull") {
            toast.success("Register Successfull");
            let res = await API.post(`/login`, {
              password: acc,
              ipaddress: ipaddress,
            });

            if (res.data.data.result === "Successfull") {
              toast.success(`Login Successful`);
              dispatch(
                updateAuth({
                  isAuth: true,
                  userId: res.data.data.uid_output,
                  jwtToken: res.data.token,
                  ipAddress: ipaddress,
                })
              );
              navigate("/Dashboard");
            } else {
              toast.error(`Invalid User id or Address`);
            }
          } else {
            toast.error(`${res.data.data.result}`);
          }
        }
      } catch (e) {
        console.log("Error while regiater", e);
      }
    }
  };

  useEffect(() => {
    let intervalId = setInterval(() => {
      walletConnected();
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);

  async function check_sponsor() {
    try {
      let res = await API.get(`/check_sponsorid?uid=${sid}`);

      // console.log("check_sponsor", res.data.data.result);
      setsponsorName(res.data.data.result);
    } catch (e) {
      console.log("Error", e);
    }
  }

  useEffect(() => {
    check_sponsor();
  }, [sid]);
  return (
    <>
      <div className="container-fluid loginpage vhUnstet">
        <div className="row h-100 align-items-center">
          <div className="col-md-4 col-sm-6 col-12 h-100 pt-md-5 mx-auto d-flex align-items-center justify-content-center flex-column">
            <div className="">
              <div className="card cardlogin py-4">
                <div className="card-body p-0">
                  <div className="row mx-0 align-items-center justify-content-center gy-3">
                    <div className="col-12">
                      <div className="loginheading text-center">
                        {" "}
                        Welcome to Celex World
                      </div>
                      <div className="subtext text-center">
                        Wallet is Locked
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        onClick={() => setregistor_Model(true)}
                        className="btn btn-theme1 logibtn w-auto mx-auto d-flex justify-content-center px-5"
                      >
                        Register
                      </button>
                    </div>
                    <div className="col-12">
                      <div className="remembertext text-center">
                        Remember to authorize with the correct address.View an
                        Account
                      </div>
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        placeholder="Enter the account id or BNB Address"
                        className="control-form"
                        value={LoginId}
                        readOnly
                      />
                    </div>
                    <div className="col-12 text-center">
                      <button className="btn btn-theme1 logibtn  px-5">
                        Connect to Wallet
                      </button>
                    </div>
                    {/* <div className="col-12">
                      <div className="remembertext text-center">
                        Enter the account id or BNB Address
                      </div>
                    </div> */}
                    <div
                      id="myModal"
                      className="re_modal"
                      style={{ display: registor_Model ? "block" : "none" }}
                    >
                      <div
                        className="modal-content-register boxset"
                        style={{
                          background: "rgb(0 202 178)",
                          border: "1px solid rgb(0 255 224)",
                          padding: "20px",
                          color: "white",
                          borderRadius: "20px",
                        }}
                      >
                        <h5 className="text-center mb-3">Enter Upline Id</h5>
                        <p>
                          {" "}
                          <input
                            id="txtsid"
                            className="control-form text-black modalinput"
                            value={sid}
                            maxLength={8}
                            type="text"
                            min={0}
                            placeholder="Enter Upline Id"
                            onChange={(e) => {
                              const inputText = e.target.value
                                .replace(/[^0-9]/gi, "")
                                .substring(0, 8);
                              const sanitizedInput =
                                DOMPurify.sanitize(inputText);
                              setSid(sanitizedInput);
                            }}
                          />
                        </p>
                        <br />
                        <p>
                          <input
                            id="txtsid"
                            className="control-form text-black modalinput"
                            value={sponsorName}
                            type="text"
                            placeholder="Sponsor Id doesnot exists !!"
                            readOnly
                          />
                        </p>
                        {/* <select
                          className="form-control"
                          id="__BVID__17"
                          value={selectPosition}
                          onChange={(e) => setselectPosition(e.target.value)}
                        >
                          <option selected>Please Select Position</option>
                          <option value="1">Left</option>
                          <option value="2">Right</option>
                        </select> */}
                        <br />
                        <div
                          className="rerlbtn"
                          style={{ textAlign: "center" }}
                        >
                          <button
                            className="btn btn_Model btnlogin modalbtn1 rounded-pill px-3"
                            onClick={() => {
                              registerUser();
                            }}
                          >
                            Register
                          </button>

                          {/* <button
                            className="mx-2 btn_Model btn btnlogin modalbtn2 rounded-pill px-3"
                            style={{ background: "red" }}
                            onClick={() => setregistor_Model(false)}
                          >
                            Close
                          </button> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-12 ">
                      <div className="remembertext2 text-center">
                        If you have already registered then{" "}
                        <Link className="Anker btn btn-theme1 logibtn  px-5" to="/login">
                          Login
                        </Link>
                      </div>
                      <div className="remembertext2 text-center mb-3">
                        Copyright © 2024. All Rights Reserved.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
