import React, { useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./Sidebar.css";
import { useSelector, useDispatch } from "react-redux";
import { updateAuth } from "../../Redux/AuthSlice";
import logo from "../../Assets/Images/logo.png";
import IconA from "../../Assets/sidebar/icon1.png";
import cardImage from "../../Assets/sidebar/gameimage.png";

// import IconB from "../../Assets/sidebar/icon2.png";
// import Icon3 from "../../Assets/sidebar/icon3.png";




export default function Sidebar() {
  const [openDropdown, setOpenDropdown] = useState(null);
  const { userId } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  const LogoutHandler = (e) => {
    dispatch(
      updateAuth({ aexnglobalAuth: false, userId: null, jwtToken: null })
    );
    localStorage.clear();
  };



  return (
    <>

      <div className="sidebar position-relative " id="sidebar">
        <Link to="/Dashboard" className="leftisidelogo">
          <img className="h-100 w-100" src={logo} alt="" />
        </Link>

        <div className="dropdown dropdowSidebar sideanker">
          <Link className="btn btnsecondary dropdown-toggle w-100 text-start" to="/Dashboard">
            Dashboard
          </Link>
        </div>
        <div className="dropdown dropdowSidebar">
          <Link className="btn btnsecondary dropdown-toggle w-100 text-start" to="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Activation
          </Link>
          <ul className="dropdown-menu">
            <li><Link className="dropdown-item" to="/Activate">Activation</Link></li>
            <li><Link className="dropdown-item" to="/Activation_History">Activation History</Link></li>
          </ul>
        </div>
        <div className="dropdown dropdowSidebar">
          <Link className="btn btnsecondary dropdown-toggle w-100 text-start" to="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Team Details
          </Link>
          <ul className="dropdown-menu">
            <li><Link className="dropdown-item" to="/My_Team">My Crew</Link></li>
            <li><Link className="dropdown-item" to="/My_Referral">My Volunteer</Link></li>
            <li><Link className="dropdown-item" to="/Level_Details">Level Details</Link></li>
            <li><Link className="dropdown-item" to="/Direct_Leg_Business">Direct Leg Business</Link></li>

          </ul>
        </div>
        <div className="dropdown dropdowSidebar">
          <Link className="btn btnsecondary dropdown-toggle w-100 text-start" to="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            All Income
          </Link>
          <ul className="dropdown-menu">
            <li><Link className="dropdown-item" to="/roiIncome">ROC Earning</Link></li>
            <li><Link className="dropdown-item" to="/levelincome">Grade Earning</Link></li>
            <li><Link className="dropdown-item" to="/RoilevelIncome">ROR Earning</Link></li>
            <li><Link className="dropdown-item" to="/ctoIncome">KINGSHIP POOL</Link></li>
            <li><Link className="dropdown-item" to="/royaltyIncome">INFINITY POOL</Link></li>
            <li><Link className="dropdown-item" to="/RewardIncome">REWARD REPORT</Link></li>
            {/* <li><Link className="dropdown-item" to="/ReleaseRoiIncome">Claim ROC Earning</Link></li> */}
            <li><Link className="dropdown-item" to="/InvestmentClaim">Claim Capital</Link></li>
            <li><Link className="dropdown-item" to="/Lifetimereward">LifeTime Reward</Link></li>
            <li><Link className="dropdown-item" to="/SalaryIncome">Salary Earning</Link></li>
          
          </ul>
        </div>
        <div className="dropdown dropdowSidebar">
          <Link className="btn btnsecondary dropdown-toggle w-100 text-start" to="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Withdrawal
          </Link>
          <ul className="dropdown-menu">
            <li><Link className="dropdown-item" to="/Working_Withdrawal">Withdrawal</Link></li>
            <li><Link className="dropdown-item" to="/Withdrawal_History">Celex Withdrawal History</Link></li>
            <li><Link className="dropdown-item" to="/Withdrawal_historyUSD">USD Withdrawal History</Link></li>
          </ul>
        </div>
        <div className="dropdown dropdowSidebar sideanker d-sm-none d-block">
          <Link className="btn btnsecondary dropdown-toggle w-100 text-start" to="/Notification">
          Notification
          </Link>
        </div>
        <div className="dropdown dropdowSidebar">
          <Link className="btn btnsecondary dropdown-toggle w-100 text-start" to="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            HelpDesk
          </Link>
          <ul className="dropdown-menu">
            <li><Link className="dropdown-item" to="/HelpDesk">Raise A Query</Link></li>
          </ul>
        </div>
        <div className="dropdown dropdowSidebar sideanker">
          <Link className="btn btnsecondary dropdown-toggle w-100 text-start" to="/" onClick={LogoutHandler}>
            Logout
          </Link>
        </div>
      </div>
    </>
  );
}
