import React, { useState } from "react";
import "./Navbar55.css";
import { Link } from "react-router-dom";

const Navbar55 = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <nav className="navbar55 fixed-top">
        <div className="dis navbar-logo55">
          <Link to="/">
            <img src="/favicon.png" alt="" className="" />
          </Link>
          {/* <div className="mobilem menu-button55 d-lg-none">
            <Link to="/login"> Sign In</Link>
          </div> */}
        </div>

        <div className={`navbar-menu55 ${isMenuOpen ? "open" : ""}`}>
          <ul className="navigation-items55">
            <li>
              <a href="#home">Home</a>
            </li>

            <li onClick={() => toggleMenu(false)}>
              <a className="scrollspy-btn" href="#about">
                About Us
              </a>
            </li>
            <li onClick={() => toggleMenu(false)}>
              <a className="scrollspy-btn" href="#token_details">
                Token Details
              </a>
            </li>
            <li onClick={() => toggleMenu(false)}>
              <a className="scrollspy-btn" href="#celex_exchange">
                Celex Exchange
              </a>
            </li>

            <li onClick={() => toggleMenu(false)}>
              <a className="scrollspy-btn" href="#faq">
                FAQ
              </a>
            </li>
            <li onClick={() => toggleMenu(false)}>
              <a className="scrollspy-btn" href="#contact_us">
                Contact Us
              </a>
            </li>
            <li onClick={() => toggleMenu(false)}>
              <a className="scrollspy-btn" href="#celexlist">
                Celex List
              </a>
            </li>

            <div className="menu-buttons-55">
              <div className="menu-button55">
                <Link to="/login"> Sign In</Link>
              </div>
              <div className="menu-button55">
                <Link to="/register">Sign Up</Link>
              </div>
            </div>
          </ul>
        </div>
        <button className="menu-toggle55" onClick={toggleMenu}>
          <div className={`menu-icon55 ${isMenuOpen ? "open" : ""}`}>
            <div className="bar55"></div>
            <div className="bar55"></div>
            <div className="bar55"></div>
          </div>
        </button>
      </nav>
    </>
  );
};

export default Navbar55;
